<script setup lang="ts">
const { setValidation, required, translationsValidation } = useValidation();
const { closeModal } = useModalStore();
const $api = useApi();

const props = defineProps({
  foodFamily: {
    type: Object,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
  onCancel: {
    type: Function,
    default: () => {},
  },
});

const subFamilyData = reactive({
  label: '',
  description: '',
  translations: [
    { lang_iso_code: 'fr', label: '', description: '' },
    { lang_iso_code: 'en', label: '', description: '' },
  ],
  portions: [],
});

const validation = setValidation(subFamilyData, {
  label: { required },
  description: { required },
  translations: { translationsValidation },
});

async function handleConfirm() {
  try {
    const { data } = await $api.post(
      `/food/family/${props.foodFamily.id}/createSubFamily`,
      subFamilyData,
    );
    closeModal();
    props.onConfirm(data);
  } catch (e) {}
}

function handleCancel() {
  closeModal();
  props.onCancel();
}
</script>

<template>
  <BaseModal
    :title="`Ajouter une sous-famille à: ${getLocaleTranslation(props.foodFamily.translations).label}`"
    :width="900"
  >
    <div class="flex flex-col gap-4">
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.description.$model"
        label="Description"
        type="text"
        :validation="validation.description"
      />

      <div>
        <strong>Traductions</strong>
        <div class="flex flex-col gap-2">
          <BaseInput
            v-for="t in subFamilyData.translations"
            v-model="t.label"
            :label="$t(`lang.${t.lang_iso_code}`)"
            type="text"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="handleCancel" />
      <BaseButton
        text="Créer"
        @click="handleConfirm"
        :disabled="validation.$invalid"
      />
    </template>
  </BaseModal>
</template>
